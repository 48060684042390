import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthInterceptor } from 'app/core/auth/auth.interceptor';

@NgModule({ imports: [], providers: [
        AuthService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AuthModule
{
}
