import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { CompanyService } from 'app/anapaya/service/company.service';
import { PermissionService } from 'app/anapaya/service/permission.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanLoadAdminGuard  {
  constructor(
    private companyService: CompanyService,
    private permissionService: PermissionService
    ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.companyService.companies.pipe(map(companies => {

      })).subscribe()

    return true;
  }
}
