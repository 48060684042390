import { APP_ID, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class OryInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if(request.url.includes("/api/")) {
      return next.handle(request)
    }

    // if (request.url.includes("/self-service/")) {
    //   request = request.clone({url: request.url.replace("/self-service/", "/.ory/self-service/")})
    // }

    // if (request.url.includes(`/sessions/`)) {
    //   request = request.clone({url: request.url.replace("/sessions/", "/.ory/sessions/")})
    // }
    return next.handle(request);
  }
}
