import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';

import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { AnapayaNavigationService } from '../service/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class AnapayaAuthGuard  {

  constructor(
    protected authService: AuthService,
    protected router: Router,  
    protected navigationService: AnapayaNavigationService
  ) {

  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    this.navigationService.section = route.data.section
    return this._check();   
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._check();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._check();
  }

    /**
   * checks if a user is logged in. If no, redirects to sign-up page.
   * @returns 
   */
  _check() {
    return this.authService.isSignedIn().pipe( map(response => {
      if (!response) {
        this.router.navigate(["/sign-in"])
      }
      return response;
    }));
  }
}
